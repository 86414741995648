import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './Home';
import Subscribe from './Subscribe';
import AdminLogin from './AdminLogin';
import Dashboard from './Dashboard';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-WVTPNHX3WP');

const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem('token');
  return isAuthenticated ? element : <Navigate to="/" />;
};

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(`Tracking page view for ${location.pathname}`);
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/join-us" element={<Subscribe />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
      </Routes>
    </Router>
  );
}

export default App;