import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo_antoo.png';
import styles from './AdminLogin.module.css';

function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popup, setPopup] = useState({ visible: false, message: '', success: false });
  const [stars, setStars] = useState([]);
  const navigate = useNavigate();

  const handleInputChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleInputChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setPopup({ visible: true, message: 'Veuillez entrer une adresse e-mail valide.', success: false });
      setTimeout(() => setPopup({ visible: false, message: '', success: false }), 3000);
      return;
    }
    localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI3NjQxNzMwLCJleHAiOjE3Mjc3MjgxMzB9.ua1r8l0biejklWk6411NLfiVgNNLAfPUvu_qoXho3EM');
    const response = await fetch('http://localhost:3000/admin/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('token', data.token);
      setPopup({ visible: true, message: 'Connexion réussie !', success: true });
      setTimeout(() => {
        setPopup({ visible: false, message: '', success: false });
        navigate('/admin/dashboard');
      }, 3000);
    } else {
      setPopup({ visible: true, message: 'Erreur lors de la connexion.', success: false });
      setTimeout(() => setPopup({ visible: false, message: '', success: false }), 3000);
    }
  };

  const generateStars = (numStars) => {
    const stars = [];
    for (let i = 0; i < numStars; i++) {
      const top = Math.random() * 100 + '%';
      const left = Math.random() * 100 + '%';
      const animationDelay = Math.random() * 5 + 's';
      const animationDuration = Math.random() * 2 + 5 + 's';
      const translateY = (Math.random() * 100 + 100) + 'vh';
      const isBlinking = Math.random() > 0.5;
      stars.push(
        <div
          key={i}
          className={`${styles.star} ${isBlinking ? styles['star-blink'] : styles['star-move']}`}
          style={{
            top,
            left,
            animationDelay,
            animationDuration,
            '--translate-y': translateY,
          }}
        ></div>
      );
    }
    return stars;
  };

  useEffect(() => {
    setStars(generateStars(100));
  }, []);

  return (
    <div className={styles.App}>
      <Helmet>
        <title>Antoo - Find the ideal partner for your projects</title>
        <meta name="description" content="Avec Antoo, trouvez le partenaire idéal pour transformer vos idées en succès." />
        <meta name="keywords" content="Matching entrepreneurial, Trouver un associé startup, Plateforme pour entrepreneurs, Réseau entrepreneurial, Partenariat startup, Application pour entrepreneurs, Communauté d’entrepreneurs, Association d’entrepreneurs, Création de startup, Collaborateur startup, Networking startup, Recherche co-fondateur, Collaboration entrepreneuriale, Mentor startup, Équipe fondatrice, Levée de fonds startup, Écosystème entrepreneurial, Événements pour entrepreneurs, Gestion de projet startup, Entrepreneuriat digital, Comment trouver un associé pour ma startup, Plateforme de mise en relation pour entrepreneurs, Créer une équipe startup performante, Outils pour startups en phase de lancement, Réseau d’entrepreneurs pour jeunes créateurs, App de matching pour entrepreneurs, Application pour trouver des partenaires business, Innovation entrepreneuriale, Algorithme de matching startup, Solutions innovantes pour startups, Nouveaux modèles d’entrepreneuriat, Startups disruptives, Entrepreneuriat en France, Startup Paris, Écosystème startup européen" />
        <meta property="og:title" content="Antoo - Trouvez le partenaire idéal pour vos projets" />
        <meta property="og:description" content="Avec Antoo, trouvez le partenaire idéal pour transformer vos idées en succès." />
        <meta property="og:image" content={logo} />
      </Helmet>
      <header className={styles.Header} id="top">
      </header>

      <main className={styles.Body}>
        <div className={styles.stars}>
          {stars}
        </div>
        <div className={styles.Title}>
          <h1>Welcome to Antoo</h1>
          <h3>Login to access to the admin dashboard</h3>
          <input
            type="email"
            value={email}
            onChange={handleInputChangeEmail}
            placeholder="Your email address"
            className={styles['email-input']}
          />
          <input
            type="password"
            value={password}
            onChange={handleInputChangePassword}
            placeholder="Your password"
            className={styles['email-input']}
          />
          <button onClick={handleSubmit} className={styles['submit-button']}>Login</button>
        </div>
        {popup.visible && (
          <div className={`${styles.popup} ${popup.success ? styles.success : styles.error} ${!popup.visible ? styles.hidden : ''}`}>
            {popup.message}
          </div>
        )}
      </main>
    </div>
  );
}

export default App;